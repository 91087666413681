import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import jwt_decode from 'jwt-decode'

import LoginView from '@/views/LoginView.vue'
import SignUpView from '@/views/SignUpView.vue'
import PerfilView from '@/views/Perfil/PerfilView.vue'

import TicketsView from '@/views/Tickets/TicketsView.vue'
import CrearTicketView from '@/views/Tickets/CrearTicketView.vue'
import VerTicketView from '@/views/Tickets/VerTicketView.vue'

import NotificacionesView from '@/views/Notificaciones/NotificacionesView'

Vue.use(VueRouter)

const routes = [
  {path: '/login',name: 'login',component: LoginView, meta: {requiresAuth: false}},
  {path: '/crear-cuenta',name: 'singup',component: SignUpView, meta: {requiresAuth: false}},
  {path: '/mi-perfil',name: 'perfil',component: PerfilView, meta: {requiresAuth: true}},

  {path: '/tickets',name: 'tickets',component: TicketsView, meta: {requiresAuth: true}},
  {path: '/notificaciones',name: 'notificaciones',component: NotificacionesView, meta: {requiresAuth: true}},
  //abrir forma edita/agregar
  {path: '/ticket/modificar/:id'    ,name: 'editTicket'   ,component: CrearTicketView, meta: {requiresAuth: true}},
 //abrir forma 
  {path: '/ticket/:id' ,name: 'verTicket'   ,component: VerTicketView, meta: {requiresAuth: true}},
 //abrir forma edita/agregar
  {path: '/nuevo-ticket',name: 'crearTicket',component: CrearTicketView, meta: {requiresAuth: true}},
  {path: '/',name: 'home',component: TicketsView, meta: {requiresAuth: true}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_SUBFOLDER,
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(item=>item.meta.requiresAuth)){
    if(!store.state.token){
      next({name: 'login'})
    }
    
    try{
      jwt_decode(store.state.token)
    }catch(error){
      next({name: 'login'})
    }
    
    if(jwt_decode(store.state.token).exp*1000 <= new Date().getTime()){
      next({name: 'login'})
    }
    next()
  }else{
    next()
  }
})

export default router
