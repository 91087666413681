<template>
    <section>
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row g-5">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-2">
                            <router-link to="/tickets" class="btn btn-outline-primary px-5 w-100 text-nowrap">Atras</router-link>
                        </div>
                        
                        <div class="col-xl-4 d-flex justify-content-end">
                            <div class="btn-group btn-group-sm mt-2" role="group" aria-label="Vista">
                                <input type="radio" class="btn-check" v-model="changeLook" :value="true" id="btnradio1" autocomplete="off" >
                                <label class="btn btn-iasc-info group-option" for="btnradio1"><ViewDayIcon /></label>

                                <input type="radio" class="btn-check" v-model="changeLook" :value="false" id="btnradio2" autocomplete="off">
                                <label class="btn btn-iasc-info group-option" for="btnradio2"><ViewArrayIcon /></label>
                            </div>
                        </div>
                    </div>

                    <div :class="['col-xl-'+(changeLook ? '12 p-1' : '8')]">
                        <div class="row gx-2">
                            <div class="col-12 card p-3 mb-3">
                                <div class="row gx-3 gy-4 ">
                                    <div class="col-12 col-sm-auto col-xl-8">
                                        <h6 class="mb-0"> Ticket: {{ ticket.id | leadingZeros(5) }} </h6>
                                    </div>
                                    <div class="col-12 col-sm-auto col-xl-8 d-flex">
                                        <Profile v-if="ticket.cte_nombre || ticket.cte_apellidos" :usuario="{nombre: ticket.cte_nombre,apellidos: ticket.cte_apellidos, ruta_foto: ticket.cte_ruta_foto}" :size="'xl'" />
                                      
                                        <div class="ps-3 d-block">
                                            <h4 class="mb-0"> {{ ticket.cte_nombre }} {{ ticket.cte_apellidos }}</h4>
                                            <p class="mb-0"> {{ ticket.cte_empleadoEmail }} </p>
                                            <p class="mb-0"> {{ ticket.empresa }} </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 ">
                                        <h5>Estatus: {{ ticket.estatus }}</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-6 ">
                                        <h5>{{ ticket.titulo }}</h5>
                                    </div>
                                    <div class="col-sm-12 col-md-6 text-end " >
                                        <p class="ps-2">{{formato(ticket.createdAt,'DD MMM, YYYY')}}</p>
                                    </div>
                                    <div class="col-sm-12 col-md-6 text-start">Sistema: {{ ticket.sistema }}</div>
                                    <div class="card p-2 col-12"><p>{{ ticket.descripcion }}</p></div>
                                </div>
                            </div>

                            <div class="col-12 card p-3">
                                <div class="row">
                                    <div class="col-8"><h4>Adjuntos</h4></div>
                                    <div class="col-4 text-end">
                                        <label for="file-upload" class="btn btn-sm  btn-outline-secondary btn-circle">+</label>
                                        <input type="file" class="d-none" id="file-upload" @change="uploadFile($event)">
                                    </div>
                                </div>

                                <div v-for="(attach,x) in adjuntos" :key="x" class="row">
                                    <div class="col-12">
                                        <pngIcon v-if="attach.tipo == 'image/png'"/>
                                        <jpgIcon v-if="attach.tipo == 'image/jpeg' || attach.tipo == 'image/jpg'"/>
                                        <pdfIcon v-if="attach.tipo == 'application/pdf'"/>
                                        <xmlIcon v-if="attach.tipo == 'text/xml'"/>
                                        <xlsIcon v-if="attach.tipo == 'application/vnd.ms-excel' || attach.tipo == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"/>
                                        <htmlIcon v-if="attach.tipo == 'text/html'"/>
                                        <gifIcon v-if="attach.tipo == 'image/gif'"/>
                                        <csvIcon v-if="attach.tipo == 'text/csv'"/>
                                        <imageIcon v-if="attach.tipo == 'image/webp'"/>
                                        <textIcon v-if="attach.tipo == 'txt/plain'"/>
                                        <a :href="$api+'/'+attach.ruta" target="_blank">{{ attach.nombre }}</a> - <span class="text-uppercase"><small style="font-size:9px">{{ formato(attach.createdAt,"DD-MMM-YYYY h:m A") }} por {{ attach.usuario_id > 0 ? attach.age_nombre+' '+attach.age_apellidos : attach.cte_nombre+' '+attach.cte_apellidos }}</small></span>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div :class="['col-xl-'+(!changeLook ? '4' : '12')]" class="card p-3">
                        <div class="row gx-2">
                            <div class="col-12 card p-3 mb-3">
                                <form @submit.prevent="addComment()">
                                    <div class="row g-3 pb-5">
                                        <h4>Seguimiento</h4>
                                        
                                        <div class=" col-md-2" >
                                            <Profile :usuario="user" :size="'l'" />
                                        </div>
                                        <div class=" col-md-10" >
                                            <div class="form-floating">
                                                <input class="form-control" v-model="newComment" id="float15" type="text" />
                                                <label for="float15">Nuevo comentario</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-12" >
                                            <button type="submit" class="btn btn-info" >Comentar</button>
                                        </div>
                                    </div>
                                </form>
                       
                                <div class="row g-3 pb-5 border-top comentarios p-3 pt-0" v-if="comentarios.length > 0">
                                    <div class=" col-12 text-end p-0" >
                                        <div class="btn-group btn-group-sm mt-2" role="group" aria-label="ordenar comentarios" v-tooltip="'Ordenar por fecha'">
                                            <input type="radio" class="btn-check"  v-model="orden" value="asc" id="asc" autocomplete="off" >
                                            <label class="btn btn-iasc-info group-option" for="asc"><ascIcon /></label>

                                            <input type="radio" class="btn-check "  v-model="orden" value="desc" id="desc" autocomplete="off">
                                            <label class="btn btn-iasc-info group-option" for="desc"><descIcon /></label>
                                        </div>
                                    </div>
                                    <div class=" col-12" v-for="(comentario,x) in sortedComentarios" :key="x">
                                        <div class="row">
                                            <div class="col-6 p-0 d-flex" >
                                                <div class="d-flex" v-if="comentario.usuario_id > 0">
                                                    <Profile :usuario="comentario.usuario" :size="'s'" />
                                                    <span class="ps-2"><b>IASC</b></span>
                                               </div>
                                               <div class="d-flex" v-if="comentario.cliente_id > 0">
                                                    <Profile :usuario="comentario.cliente" :size="'s'" />
                                                    <span class="ps-2"><b>{{ comentario.cte_nombre }} {{ comentario.cte_apellidos }}</b></span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-end  p-0">
                                            </div>
                                            <div class="col-12 ps-1 card p-2">
                                                <span>{{ comentario.id }} - {{ comentario.descripcion }}</span>
                                            </div>
                                            
                                            <div class="col-12 ps-1 text-end">
                                                <span class="text-uppercase"><small >{{ formato(comentario.createdAt,'DD-MMM-YYYY h:m A') }}</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import moment from 'moment'
  import StarIcon from 'vue-material-design-icons/Star.vue';
  import StarOutlineIcon from 'vue-material-design-icons/StarOutline.vue';
  import ViewDayIcon from 'vue-material-design-icons/ViewDay.vue';
  import ViewArrayIcon from 'vue-material-design-icons/ViewArray.vue';
  import EyeOffOutlineIcon from 'vue-material-design-icons/EyeOffOutline.vue';
  import CloseCircleOutlineIcon from 'vue-material-design-icons/CloseCircleOutline.vue';

  import pngIcon from 'vue-material-design-icons/FilePngBox.vue';
  import jpgIcon from 'vue-material-design-icons/FileJpgBox.vue';
  import pdfIcon from 'vue-material-design-icons/FilePdfBox.vue';
  import xmlIcon from 'vue-material-design-icons/FileXmlBox.vue';
  import xlsIcon from 'vue-material-design-icons/FileExcel.vue';
  import htmlIcon from 'vue-material-design-icons/LanguageHtml5.vue';
  import gifIcon from 'vue-material-design-icons/FileGifBox.vue';
  import imageIcon from 'vue-material-design-icons/Image.vue';
  import textIcon from 'vue-material-design-icons/TextBox.vue';
  import csvIcon from 'vue-material-design-icons/FileDelimited.vue';

  import ascIcon from 'vue-material-design-icons/OrderAlphabeticalAscending.vue';
  import descIcon from 'vue-material-design-icons/OrderAlphabeticalDescending.vue';
  import { defineAsyncComponent } from 'vue'

  export default {
    name: 'VerTicketView',
    
    components:{
        Profile: defineAsyncComponent(() =>
            import('@/components/Profile.vue')
        ),
        ascIcon,descIcon,TopBar,moment,StarOutlineIcon,StarIcon,ViewDayIcon,ViewArrayIcon,EyeOffOutlineIcon,CloseCircleOutlineIcon,
        textIcon,imageIcon,gifIcon,htmlIcon,xlsIcon,xmlIcon,jpgIcon,pngIcon,pdfIcon,csvIcon},
    data(){
        return{
            user: JSON.parse(Buffer.from(this.$store.state.token.split('.')[1], 'base64').toString()),
            ticket:  {},
            id: this.$route.params.id,
            orden: 'asc',
            option:{
                defaultContent: '',
                textColor:'black'
            },
            changeLook:false,
            newComment:'',
            comentarios:[],
            str_file: '',
            adjunto: {},
            adjuntos: [],
        }
    },
    created(){
       this.getData()
    },
   computed:{
        sortedComentarios(){
            if(this.orden == 'asc'){
                return this.comentarios.sort((a,b) => a.id - b.id)
            }else{
                return this.comentarios.sort((a,b) => b.id - a.id)
            }
        },
   },
    methods:{
        formato(fecha,formato){
            return moment(fecha).format(formato)
        },
      
        getData(){
             axios.get(this.$api+'/ticket-cte/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.ticket = res.data.ticket
                    this.adjuntos = res.data.adjuntos
                    this.comentarios = res.data.respuestas
                    this.comentarios.forEach((c)=>{
                        c.usuario = {
                            id: c.usuario_id,
                            nombre: 'IASC',
                            apellidos: '',
                            ruta_foto: c.age_ruta_foto
                        }
                        c.cliente = {
                            id: c.cliente_id,
                            nombre: c.cte_nombre,
                            apellidos: c.cte_apellidos,
                            ruta_foto: c.cte_ruta_foto
                        }
                    })
                    
                    this.ticket.createdAt = moment(this.ticket.createdAt).format('DD-MMMM-YYYY')
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
               this.$notify({
                       group: 'iasc',
                       title: 'Error',
                       text: err.response.data.message,
                       type: 'error'
                   });
           })
        },
        addComment(){
            let comentario = {}
            comentario.ticket_id = this.$route.params.id
            comentario.descripcion = this.newComment
         
            axios.post(this.$api+'/respuesta/cte',comentario,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    
                    this.comentarios = res.data
                    this.comentarios.forEach((c)=>{
                        c.usuario = {
                            id: c.usuario_id,
                            nombre: c.age_nombre,
                            apellidos: c.age_apellidos,
                            ruta_foto: c.age_ruta_foto
                        }
                        c.cliente = {
                            id: c.cliente_id,
                            nombre: c.cte_nombre,
                            apellidos: c.cte_apellidos,
                            ruta_foto: c.cte_ruta_foto
                        }
                    })
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
            this.newComment = ''
        },
      
       uploadFile($event){
        var image 
        if($event.target.files.length >= 1){
            image =  $event.target.files[0]
        }

        if(image.size <= 2000000){
            if( image.type == 'image/jpg' || 
                image.type == 'image/jpeg' || 
                image.type == 'image/png' || 
                image.type == 'image/gif' || 
                image.type == 'text/xml' || 
                image.type == 'text/html' || 
                image.type == 'image/webp' || 
                image.type == 'text/csv' || 
                image.type == 'txt/plain' || 
                image.type == 'application/pdf' || 
                image.type == 'application/vnd.ms-excel' || 
                image.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                this.str_file = URL.createObjectURL(image)
                
                this.adjunto = image
                let fm = new FormData()
                fm.append('ticket_id',this.id)
                fm.append('adjunto',this.adjunto) 
                fm.append('tipo',image.type) 
              
                axios.post(this.$api+'/adjunto/cte',fm,{
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization' : this.$store.state.token
                    }
                }).then((res)=>{
                    if(res.data.message){
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: res.data.message,
                            type: 'error'
                        });
                    }else{
                        this.adjuntos.push(res.data)
                    }
                }).catch((err)=>{
                    if(err.response) {
                        if(err.response.status == 500) this.logout()
                    }
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
                })
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El tipo de archivo no se permite',
                    type: 'error'
                });
            }
        }else{
            this.$notify({
                group: 'iasc',
                title: 'Error',
                text: 'El archivo debe pesar menos de 2Mb',
                type: 'error'
            });
        }
       },
       
    }
  }
  </script>
  