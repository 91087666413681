<template>
  <div id="app"  >
    <main class="main" id="top">
      <div class="container-fluid px-0" data-layout="container">
        <notifications  group="iasc" />
        <AvisosApp v-for="(aviso,x) in avisos" :key="x" :indx="x" :aviso="aviso" />
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
  import axios from 'axios'
  import AvisosApp from './components/AvisosApp.vue'
  //import ChatSupport from '@/components/ChatSupport.vue'
  export default {
    components:{AvisosApp},
    data(){
      return{
        avisos: []
      }
    },
    created(){
      //this.getAvisos()
    },
    methods:{
      getAvisos(){
        axios.get(this.$api+'/vigentes',{
            headers: {
                'Content-Type' : 'application/json'
            }
        })
        .then((res)=>{
            console.log(res)
            this.avisos = res.data
        }).catch((err)=>{
            if(err.response) {
                if(err.response.status == 500) this.logout()
            }
            this.$notify({
                group: 'iasc',
                title: 'Error',
                text: err.response.data.message,
                type: 'error'
            });
        })
      }
    }
  }
</script>