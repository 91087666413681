<template>
    <section>
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-6">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Ticket</h2>
                        <h2 class="mb-0" v-else >Modificando Ticket</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <router-link to="/tickets" class="btn btn-outline-primary px-5 w-100 text-nowrap">Atras</router-link>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-4">
                        <div class="d-flex">
                            <button type="button" @click="validar()" class="btn btn-primary w-100 text-nowrap">Publicar ticket</button>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-md-8 offset-md-2">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6 offset-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="ticket.createdAt" disabled id="float15" type="date"  />
                                    <label for="float15">Fecha</label>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-md-8">
                                <div class="form-floating">
                                    <input class="form-control" v-model="ticket.titulo" id="float5" type="text" placeholder="Titulo del ticket" />
                                    <label for="float5">Titulo</label>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="ticket.sistema_id" id="float4" >
                                        <option disabled selected="selected">Selecciona...</option>
                                        <option v-for="(sistema,x) in sistemas" :key="x" :value="sistema.id">{{sistema.nombre}}</option>
                                    </select>
                                    <label for="float4">Sistema</label>
                                </div>
                            </div>
                            
                            <div class="col-12 gy-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="ticket.descripcion" id="floatingProjectDescription" style="height: 300px"></textarea>
                                    <label for="floatingProjectDescription">Descripción</label>
                                </div>
                            </div>
                            <div class="col-12 card p-3">
                                <div class="row">
                                    <div class="col-8"><h4>Adjuntos</h4></div>
                                    <div class="col-4 text-end">
                                        <label for="file-upload" class="btn btn-sm  btn-outline-secondary btn-circle">+</label>
                                        <input type="file" class="d-none" id="file-upload" @change="uploadFile($event)">
                                    </div>
                                </div>

                                <div v-for="(attach,x) in adjuntos" :key="x" class="row">
                                    <div class="col-12">
                                        <pngIcon v-if="attach.tipo == 'image/png'"/>
                                        <jpgIcon v-if="attach.tipo == 'image/jpeg' || attach.tipo == 'image/jpg'"/>
                                        <pdfIcon v-if="attach.tipo == 'application/pdf'"/>
                                        <xmlIcon v-if="attach.tipo == 'text/xml'"/>
                                        <xlsIcon v-if="attach.tipo == 'application/vnd.ms-excel' || attach.tipo == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"/>
                                        <htmlIcon v-if="attach.tipo == 'text/html'"/>
                                        <gifIcon v-if="attach.tipo == 'image/gif'"/>
                                        <csvIcon v-if="attach.tipo == 'text/csv'"/>
                                        <imageIcon v-if="attach.tipo == 'image/webp'"/>
                                        <textIcon v-if="attach.tipo == 'txt/plain'"/>
                                        <span class="a" >{{ attach.nombre }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import InputTag from '@johmun/vue-tags-input'
  import moment from 'moment'

  import pngIcon from 'vue-material-design-icons/FilePngBox.vue';
  import jpgIcon from 'vue-material-design-icons/FileJpgBox.vue';
  import pdfIcon from 'vue-material-design-icons/FilePdfBox.vue';
  import xmlIcon from 'vue-material-design-icons/FileXmlBox.vue';
  import xlsIcon from 'vue-material-design-icons/FileExcel.vue';
  import htmlIcon from 'vue-material-design-icons/LanguageHtml5.vue';
  import gifIcon from 'vue-material-design-icons/FileGifBox.vue';
  import imageIcon from 'vue-material-design-icons/Image.vue';
  import textIcon from 'vue-material-design-icons/TextBox.vue';
  import csvIcon from 'vue-material-design-icons/FileDelimited.vue';
  
  export default {
    name: 'CrearTicketView',
    components:{TopBar,InputTag,moment,textIcon,imageIcon,gifIcon,htmlIcon,xlsIcon,xmlIcon,jpgIcon,pngIcon,pdfIcon,csvIcon},
    data(){
        return{
            modo: 'Creando',
            user: JSON.parse(Buffer.from(this.$store.state.token.split('.')[1], 'base64').toString()),
            ticket:  {
                createdAt: moment(new Date()).format('YYYY-MM-DD'),
                adjuntos: []
            },
            msg_err: '',
            recuerda_token: true,
            adjuntos:[],
            sistemas:{},
        }
    },
    created(){
        this.getSistemasEmpresa()
    },
    methods:{
        validar(){
            if(!this.ticket.titulo){
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'ingrese un titulo',
                        type: 'error'
                    });
              
            }else if(!this.ticket.descripcion){
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'ingrese una descripcion',
                        type: 'error'
                    });
                
            }else{
                this.setTicket()
            }
        },
        setTicket(){
            let data
            let headerType = ''
           
            if(this.adjuntos.length > 0){
                data = new FormData()
                data.append('createdAt',this.ticket.createdAt)
                data.append('sistema_id',this.ticket.sistema_id)
                data.append('titulo',this.ticket.titulo)
                data.append('cliente_id',this.ticket.cliente_id)
                data.append('empresa_id',this.ticket.empresa_id)
                data.append('tags',JSON.stringify(this.tags))
                data.append('descripcion',this.ticket.descripcion)
                data.append('visible',this.ticket.visible)
                data.append('tipo_id',this.ticket.tipo_id)
                data.append('categoria_id',this.ticket.categoria_id)
                data.append('estatus_id',this.ticket.estatus_id)
                data.append('prioridad_id',this.ticket.prioridad_id)
                data.append('usuario_id',this.ticket.usuario_id)
                data.append('registro_usuario_id',this.ticket.registro_usuario_id)
                for(var i=0; i < this.ticket.adjuntos.length; i++){
                    data.append('adjuntos[]',this.ticket.adjuntos[i])
                }
                headerType = 'multipart/form-data'
            }else{
                data = this.ticket
                data.tags = JSON.stringify(this.tags)
                headerType = 'application/json'
            }
        
            axios.post(this.$api+'/ticket-cte',data,{
                headers: {
                    'Content-Type' : headerType,
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.$router.push({name: 'tickets'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
            })
        },
        getSistemasEmpresa(){
            axios.get(this.$api+'/sistemasxcte-cte',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.sistemas = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        uploadFile($event){
            var image 
            if($event.target.files.length >= 1){
                image =  $event.target.files[0]
            }

            if(image.size <= 2000000){
                if( image.type == 'image/jpg' || 
                    image.type == 'image/jpeg' || 
                    image.type == 'image/png' || 
                    image.type == 'image/gif' || 
                    image.type == 'text/xml' || 
                    image.type == 'text/html' || 
                    image.type == 'image/webp' || 
                    image.type == 'text/csv' || 
                    image.type == 'txt/plain' || 
                    image.type == 'application/pdf' || 
                    image.type == 'application/vnd.ms-excel' || 
                    image.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    this.str_file = URL.createObjectURL(image)
                    
                    //local
                    let tmp = {}
                    tmp.nombre = image.name
                    tmp.tipo = image.type
                    this.adjuntos.push(tmp)
                    this.ticket.adjuntos.push(image)
                    //
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El tipo de archivo no se permite',
                        type: 'error'
                    });
                }
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El archivo debe pesar menos de 2Mb',
                    type: 'error'
                });
            }
        }
    }
  }
  </script>
  