<template>
    <section>
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                    <h2 class="mb-0">Tickets</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <router-link to="/nuevo-ticket" class="btn btn-primary px-5 w-100 text-nowrap">Nuevo Ticket</router-link>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="card" id="table" >
                                <div class="card-header" v-if="tickets.length > 0">
                                    <div class="row align-items-center">
                                        <div class="col-1 d-flex">
                                            <div class="form-check">
                                                <input class="form-check-input select-cbox" type="checkbox" v-model="allSelected" @click="selectAll()" >
                                            </div>
                                        
                                            <div class="btn-group dropend mb-2">
                                                <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">...</button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#" @click="archivar()" >Archivar</a>
                                                    <div class="dropdown-divider">Mostrar</div>
                                                    <a class="dropdown-item" href="#" @click="getData()">Ver solo tickets</a>
                                                    <a class="dropdown-item" href="#" @click="getDataTodos()">Ver todos los tickets</a>
                                                    <a class="dropdown-item" href="#" @click="getDataArchivados()">Ver solo archivados</a>
                                                </div>
                                            </div>                                        
                                        </div>
                                        <div class="col-6">
                                            <form>
                                                <div class="input-group input-group-flush input-group-merge input-group-reverse">
                                                    <span class="input-group-text search"><MagnifyIcon /></span>
                                                    <input class="form-control list-search" v-model="filtro" type="buscar" placeholder="Buscar..">
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-floating">
                                                <select class="form-select" v-model="estatusSelected" >
                                                    <option v-for="(est,e) in estatus" :key="e" :value="est.id">{{est.nombre}}</option>
                                                    <option value="*">Todos</option>
                                                </select>
                                                <label for="floatingSelectTask">Mostrar</label>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-floating">
                                                <select class="form-select" v-model="perPage" >
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="150">150</option>
                                                    <option value="200">200</option>
                                                    <option value="250">250</option>
                                                </select>
                                                <label for="floatingSelectTask">Tickets x página</label>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div v-if="tickets.length > 0">
                                    <table class="table table-sm fs--1 mb-0">
                                        <paginate 
                                            tag="tbody"
                                            name="tickets"
                                            ref="tickets"
                                            :per="perPage"
                                            class=""
                                            :list="filteredTickets">
                                                <tr v-for="(ticket,x) in paginated('tickets')" :key="x" class="ticket-row cursor-pointer" @dblclick="open(ticket.id)">
                                                    <td>
                                                        <div class="d-flex align-middle">
                                                            <div class="ps-3">
                                                                <div class="form-check">
                                                                    <input class="form-check-input select-cbox" type="checkbox" v-model="selectedIds" @click="select()" :value="ticket.id">
                                                                </div>
                                                            </div>
                                                            <div class="pe-3 ps-3">{{ticket.id | leadingZeros(5)}} <FolderOutlineIcon v-tooltip="'Archivado'" v-if="ticket.archivado > 0" /> </div>
                                                            <div v-tooltip="ticket.estatus">
                                                                <Component :is="ticket.estatus_icono+'Icon'" class="d-inline-flex" :class="['text-'+ticket.estatus_color]" style="height:16px;width:16px;" />
                                                            </div>
                                                            <div class="pe-3 ps-3 ">{{formato(ticket.createdAt,'DD-MMM-YYYY')}}</div>
                                                            <div class="pe-3 flex-fill ticket-desc"><span class="text-nowrap overflow-hidden">{{ticket.descripcion }}</span></div>
                                                            <div class=" align-middle gx-1">
                                                                <span class="badge badge-iasc fs--2 badge-iasc-secondary" @click="filtro += ' '+ticket.sistema" v-tooltip="'Sistema'"><span class="badge-label">{{ticket.sistema}}</span></span>
                                                            </div>
                                                            <div class="ps-3 ">
                                                                <router-link class="btn btn-sm btn-outline-secondary" :to="'/ticket/'+ticket.id" v-tooltip="'Abrir Ticket'"><OpenInAppIcon /></router-link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </paginate>
                                    </table>
                                </div>
                                <div v-else class="p-5">
                                    <div class="p-5 m-auto text-center w-50">
                                        <p>No tienes tickets de soporte por el momento.<br>
                                         Puedes comenzar dando clic en el botón 'Nuevo Ticket' para iniciar el proceso de soporte de IASC
                                        </p>
                                        <router-link to="/nuevo-ticket" class="btn btn-outline-primary">Nuevo Ticket</router-link>
                                    </div>
                                </div>
                                <div v-if="$refs.tickets" class="d-flex flex-between-center pt-3">
                                    <div class="row align-items-center justify-content-between py-2 pe-0 fs--1">
                                        <div class="col-auto d-flex">
                                            <p class="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900" data-list-info="data-list-info">
                                                <span class="text-600"  v-if="$refs.tickets"> Mostrando </span> {{$refs.tickets.pageItemsCount}}
                                            </p>
                                            <a v-if="filteredTickets.length > perPage" class="fw-semi-bold list-pagination-all" @click="perPage = filteredTickets.length" href="#!" >Ver todo<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                            <a v-if="filteredTickets.length > perPage" class="fw-semi-bold d-none list-pagination-all" href="#!" @click="perPage = 20" >Ver menos<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                        </div>
                                        <div class="col-auto d-flex">
                                            <button type="button" class="page-link " @click="goToPrev()" data-list-pagination="prev" >
                                                <svg class="svg-inline--fa fa-chevron-left" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"></path></svg><!-- <span class="fas fa-chevron-left"></span> Font Awesome fontawesome.com -->
                                            </button>

                                            <paginate-links @change="onLangsPageChange" 
                                                v-if="$refs.tickets" 
                                                for="tickets" 
                                                :limit="5"
                                                :classes="{'ul': ['mb-0','list-pagination','pagination','pagination-tabs','card-pagination'],'a': ['page']}"></paginate-links>
                                                
                                            <button type="button"  class="page-link pe-0" @click="goToNext()" data-list-pagination="next">
                                                <svg class="svg-inline--fa fa-chevron-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                                                    <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                                                </svg><!-- <span class="fas fa-chevron-right"></span> Font Awesome fontawesome.com -->
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
        <Footer />
    </section>
  </template>
  
  <script>
  import moment from 'moment'
  import TopBar from '@/components/TopBarNav.vue'
  import Footer  from '@/components/Footer.vue'
  import axios from 'axios'
  import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
  import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
  import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue';
  import ProgressCheckIcon from 'vue-material-design-icons/ProgressCheck.vue';
  import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
  import OpenInAppIcon from 'vue-material-design-icons/OpenInApp.vue';
  import CancelIcon from 'vue-material-design-icons/Cancel.vue';
  import StarCheckIcon from 'vue-material-design-icons/StarCheck.vue';
  import StarIcon from 'vue-material-design-icons/Star.vue';
  import StarOutlineIcon from 'vue-material-design-icons/StarOutline.vue';
  import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';

  export default {
    name: 'TicketsView',
    components:{Footer,moment,OpenInAppIcon,FolderOutlineIcon,TopBar,MagnifyIcon,StarIcon,AlertCircleOutlineIcon,StarOutlineIcon,ProgressClockIcon,ProgressCheckIcon,CheckCircleOutlineIcon,CancelIcon,StarCheckIcon},
    data(){
        return{
            tickets:[],
            filtro:'',
            paginate: ['tickets'],
            currentPage:1,
            perPage:20,
            estatusSelected: '*',
            estatus:[],
            selectedIds:[],
            selected: [],
            allSelected: false,
        }
    },
    created(){
        this.getEstatus()
        this.getData()
    },
    computed:{
        filteredTickets(){
            let res = this.tickets.filter((e)=>{
                if(this.filtro != ''){
                 
                    return ((e.titulo) ? e.titulo.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '' ) || 
                           ((e.descripcion) ? e.descripcion.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '') || 
                           ((e.createdAt) ? e.createdAt.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '') || 
                           ((e.sistema) ? e.sistema.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.id) ? e.id.toString().includes(this.filtro.toLowerCase()) : '') 
                }else{
                    return e
                }
            })
            return res
        }
    },
    methods:{
        formato(fecha,formato){
            return moment(fecha).format(formato)
        },
        onLangsPageChange(toPage,fromPage){
            this.currentPage = toPage
        },
        goToPrev(){
            if(this.currentPage > 1){
                this.currentPage = this.currentPage - 1
                this.$refs.tickets.goToPage(this.currentPage)
            }else{
                this.$refs.tickets.goToPage(0)
            }
        },
        goToNext(){
            if(this.currentPage <= Math.ceil( this.filteredTickets.length/this.perPage)){
                this.currentPage = this.currentPage + 1
                this.$refs.tickets.goToPage(this.currentPage)
            }else{
                this.$refs.tickets.goToPage(Math.ceil( this.filteredTickets.length/this.perPage))
            }
        },
        getData(){
             axios.get(this.$api+'/tickets-cte',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataTodos(){
            axios.get(this.$api+'/tickets-cte-warchive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                console.log(res.data)
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataArchivados(){
            axios.get(this.$api+'/tickets-cte-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        selectAll() {
            this.selectedIds = [];
            const pagelisted = this.paginated('tickets')
            if(!this.allSelected){
                for(var i=0;i < pagelisted.length;i++) {
                    this.selectedIds.push(pagelisted[i].id.toString());
                }
            }
        },
        select() {
            this.allSelected = false;
        },
        archivar(){
            const data = {
                ticket_ids : this.selectedIds
            }
            axios.post(this.$api+'/ticket-cte-archivar',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.selectedIds = []
                    this.getData()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getEstatus(){
             axios.get(this.$api+'/estatuses-cte',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.estatus = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        open(id){
            this.$router.push({name:'verTicket', params: { id: id }})
        }
    }
  }
  </script>
  