<template>
   <footer class="footer position-absolute">
      <div class="row g-0 justify-content-end align-items-center h-100">
        <div class="col-12 col-sm-auto text-end">
          <p class="mb-0 mt-2 mt-sm-0 text-end text-900">Ingeniería Avanzada en Sistemas Computacionales S.A. de C.V.<span class="d-none d-sm-inline-block"></span><span class="d-none d-sm-inline-block mx-1">|</span><br class="d-sm-none" />&copy; {{year}} </p>
        </div>
      </div>
    </footer>
</template>

<script>


export default {
    name: 'FooterNav',
    data(){
        return{
          year: new Date().getFullYear()
        }
      }
  }
</script>