<template>
    <div class="row vh-100 flex-cente g-0">
          <div class="col-lg-6 position-relative d-none d-lg-block">
            <div class="bg-holder" :style="cssProps">
            </div>
          </div>
          <div class="col-lg-6 m-auto">
            <form @submit.prevent="validar()">
              <div class="row flex-center h-100 g-0 px-4 px-sm-0">
                <div class="col col-sm-6 col-lg-7 col-xl-6"><a class="d-flex flex-center text-decoration-none mb-4" href="/">
                    <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block"><img src="@/assets/img/logo.png" alt="iasc" width="58" />
                    </div>
                  </a>
                  <div class="text-center mb-7">
                    <h3 class="text-1000">Iniciar Sesión</h3>
                    <p class="text-700">Para obtener soporte en IASC</p>
                  </div>
                  
                  <div class="mb-3 text-start">
                      <div class="alert alert-danger" v-if="msg_err" role="alert">
                          {{msg_err}}
                      </div>
                    <label class="form-label" for="email">Email</label>
                    <div class="form-icon-container">
                      <input class="form-control form-icon-input" id="email" v-model="email" type="email" placeholder="nombre@ejempl.com" /><span class="fas fa-user text-900 fs--1 form-icon"></span>
                    </div>
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="password">Contraseña</label>
                    <div class="form-icon-container">
                      <input class="form-control form-icon-input" id="password" v-model="password" type="password" placeholder="Contraseña" /><span class="fas fa-key text-900 fs--1 form-icon"></span>
                    </div>
                  </div>
                  <div class="row flex-between-center mb-7">
                    <div class="col-auto">
                      <div class="form-check mb-0">
                        <input class="form-check-input" id="basic-checkbox" v-model="recuerda_token" type="checkbox" checked="checked" />
                        <label class="form-check-label mb-0" for="basic-checkbox">Recordar mi sesión</label>
                      </div>
                    </div>
                    <div v-show="false" class="col-auto"><a class="fs--1 fw-semi-bold" href="../../../pages/authentication/simple/forgot-password.html">¿Olvidaste tu contraseña?</a></div>
                  </div>
                  <button type="submit" class="btn btn-primary w-100 mb-3">Iniciar Sesión</button>
                  <div v-show="false" class="text-center"><router-link to="/crear-cuenta" class="fs--1 fw-bold" >Crea una cuenta</router-link ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios'
  export default {
    name: 'LoginView',
    data(){
        return{
            email: '',
            password: '',
            msg_err: '',
            recuerda_token: true,
            show:false,
            cssProps: {
              backgroundImage: `url(${require('@/assets/img/bkg.jpg')})`
            }
        }
    },
    methods:{
        validar(){
            if(!this.email){
                this.msg_err = 'ingrese un correo'
            }else if(!this.password){
                this.msg_err = 'ingrese una contraseña'
            }else{
                this.msg_err = ''
                this.login()
            }
        },
        login(){
          let data = {
              email: this.email,
              password: this.password,
              recuerda_token: this.recuerda_token
          }
          axios.post(this.$api+'/cliente_login',data,{
              headers: {
                  'Content-Type' : 'application/json'
              }
          }).then((res)=>{
              if(res.data.message){
                  this.msg_err = res.data.message
              }
              if(res.data.token){
                  this.$store.dispatch('saveToken',res.data.token)
                  this.$router.push({name: 'tickets'})
              }
          }).catch((err)=>{
            this.msg_err= err.response.data.message
          })
        }
    }
  }
  </script>
  