<template>
    <div class="row vh-100 flex-cente g-0">
          <div class="col-lg-6 position-relative d-none d-lg-block">
            <div class="bg-holder" :style="cssProps">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row flex-center h-100 g-0 px-4 px-sm-0">
              <div class="col col-sm-6 col-lg-7 col-xl-6"><a class="d-flex flex-center text-decoration-none mb-4" href="/">
                  <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block"><img src="@/assets/img/logo.png" alt="IASC" width="58" />
                  </div>
                </a>
                <div class="text-center mb-7">
                  <h3 class="text-1000">Crear Cuenta</h3>
                  <p class="text-700">Crea tu cuenta para recibir soporte con IASC</p>
                </div>
                <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                    </div>
                <form @submit.prevent="validar()">
                  
                  <div class="mb-3 text-start">
                    <label class="form-label" for="name">Nombre</label>
                    <input class="form-control" id="name" v-model="cliente.nombre" type="text" placeholder="Nombre" />
                  </div>
                  <div class="mb-3 text-start">
                    <label class="form-label" for="email">Email</label>
                    <input class="form-control" id="email" v-model="cliente.email" type="email" placeholder="nombre@ejemplo.com" />
                  </div>
                  <div class="row g-3 mb-3">
                    <div class="col-md-6">
                      <label class="form-label" for="password">Contraseña</label>
                      <input class="form-control form-icon-input" v-model="cliente.password"  id="password" type="password" placeholder="Contraseña" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label" for="confirm_password">Confirmar Contraseña</label>
                      <input class="form-control form-icon-input" v-model="cliente.confirmPassword" id="confirm_password" type="password" placeholder="Confirmar Contraseña" />
                    </div>
                  </div>
                  <div class="form-check mb-3">
                    <input class="form-check-input" id="termsService" v-model="terms" type="checkbox" />
                    <label class="form-label fs--1 text-none" for="termsService">Acepto <a href="#!">términos de condiciones </a>y <a href="#!">políticas de privacidad</a></label>
                  </div>
                  <button type="submit" class="btn btn-primary w-100 mb-3">Crear cuenta</button>
                  <div class="text-center"><router-link class="fs--1 fw-bold" to="/login">Iniciar sesión con cuenta existente</router-link></div>
                </form>
              </div>
            </div>
          </div>
        </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios'
  export default {
    name: 'LoginView',
    data(){
        return{
          cliente:{},
          terms: true,
          show:false,
          msg_err:'',
          cssProps: {
              backgroundImage: `url(${require('@/assets/img/bkg.jpg')})`
            }
        }
    },
    methods:{
        validar(){
          if(!this.terms){
            this.msg_err = 'Debes aceptar nuestros términos y condiciones y políticas de privacidad para poder crear una cuenta'
          }else if(this.cliente.nombre == ''){
           this.msg_err = 'Favor de introducir tu nombre'
          }else if(this.cliente.email == ''){
            this.msg_err = 'Favor de introducir el correo electrónico'
          }else if(this.cliente.password == ''){
           this.msg_err = 'Favor de introducir una contraseña'
          }else if(this.cliente.confirmPassword == ''){
           this.msg_err = 'Favor de confirmar contraseña'
          }else if((this.cliente.confirmPassword != '' && this.cliente.password != '') && this.cliente.password != this.cliente.confirmPassword){
           this.msg_err = 'La contraseña y la confirmación no coinciden'
          }else{
            this.msg_err = ''
            axios.post(this.$api+'/clientes/create',this.cliente,{
              headers: {
                    'Content-Type' : 'application/json'                
                  }
            }).then(()=>{
              this.$router.push({name: 'tickets'})
            })
          }
      }
    }
  }
  </script>
  