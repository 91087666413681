import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            
        }
    },
    methods:{
        logout(){
            this.$store.dispatch('logout')
            this.$router.push({name: 'login'})
            console.log('logout')
        },
        borrar(pathName,id){
            axios.post(this.$api+'/'+pathName+'/delete/'+id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then(()=>{
                this.init_data()
            }).catch((err)=>{
                console.log(err)
            })
        },
        formato(fecha,formato){
            return moment(fecha).format(formato)
        }
    }
}