<template>
    <section>
        <div class="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
            <div class="row">
              <div class="col-xl-9">
                <div class="d-sm-flex justify-content-between">
                  <h2 class="mb-4">Create a new lead</h2>
                  <div class="d-flex mb-3">
                    <button class="btn btn-iasc-primary me-2 px-6">Cancel</button>
                    <button class="btn btn-primary">Create lead</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-9">
              <div class="d-flex align-items-end position-relative mb-7 mb-xxl-0">
                <input class="d-none" id="upload-avatar" type="file" />
                <div class="position-relative bg-400 rounded-circle cursor-pointer d-flex flex-center mb-xxl-7" style="height:150px;width:150px;">
                  <div class="avatar avatar-5xl hoverbox">
                    <img class="rounded-circle" src="" alt="" />
                    <div class="hoverbox-content bg-black rounded-circle d-flex flex-center" style="--iasc-bg-opacity: .56;">
                      <span class="fa-solid fa-camera fs-7 text-300"></span>
                    </div>
                  </div>
                  <label class="w-100 position-absolute h-100 z-index-2" for="upload-avatar"></label>
                </div>
              </div>
              <h4 class="mb-3">Datos personales</h4>
              <form class="row g-3 mb-9">
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputFirstname" type="text" placeholder="Nombre" />
                    <label for="floatingInputFirstname">Nombre</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputLastname" type="text" placeholder="Apellidos" />
                    <label for="floatingInputLastname">Apellidos</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputCompany" type="text" placeholder="Company" />
                    <label for="floatingInputCompany">Empresa</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputTitle" type="text" placeholder="title" />
                    <label for="floatingInputTitle">Puesto</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputEmail" type="text" placeholder="email" />
                    <label for="floatingInputEmail">Email</label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputPhone" type="tel" placeholder="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                    <label for="floatingInputPhone">Whatsapp</label>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end mt-6">
                  <button class="btn btn-primary">Guardar cambios</button>
                </div>
              </form>
            </div>
          </div>
    </section>
</template>

<script>
  // @ is an alias to /src
import TopBar from '@/components/TopBarNav.vue'
import Footer from '@/components/Footer.vue'

import handleGlobals from '@/mixins/handleGlobals';
import axios from 'axios'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import AccountTag from 'vue-material-design-icons/AccountTag.vue';
import AttachmentPlus from 'vue-material-design-icons/AttachmentPlus.vue';
import ListStatus from 'vue-material-design-icons/ListStatus.vue';
import MessagePlusOutline from 'vue-material-design-icons/MessagePlusOutline.vue';
import At from 'vue-material-design-icons/At.vue';
import TagPlusOutline from 'vue-material-design-icons/TagPlusOutline.vue';
import EmailOutline from 'vue-material-design-icons/EmailOutline.vue';
import EmailOpenOutline from 'vue-material-design-icons/EmailOpenOutline.vue';
import { defineAsyncComponent } from 'vue'

import moment from 'moment'
  export default {
    name: 'NotificacionesView',
    components:{Footer,TopBar,MagnifyIcon, Profile: defineAsyncComponent(() =>
            import('@/components/Profile.vue')
        ),AccountTag,AttachmentPlus,ListStatus,MessagePlusOutline,TagPlusOutline,At,EmailOutline,EmailOpenOutline},
    mixins:[handleGlobals],
    data(){
        return{
            notificaciones:[],
            user: JSON.parse(this.$usuario)
        }
    },
    created(){
        this.getData()
    },
    computed:{
        notificacionesHoy(){
            let hoy = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.createdAt).format('YYYY-MM-DD') ==  moment(hoy).format('YYYY-MM-DD')
            })
            return notes
        },
        notificacionesAyer(){
            let ayer = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.createdAt).format('YYYY-MM-DD') == moment(ayer).subtract(1, 'days').format('YYYY-MM-DD') 
            })
            return notes
        },
        notificacionesResto(){
            let ayer = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.createdAt).format('YYYY-MM-DD') < moment(ayer).subtract(1, 'days').format('YYYY-MM-DD') 
            })
            return notes
        }
    },
    methods:{
        getData(){
            axios.get(this.$api+'/clientes/find',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.notificaciones = res.data
            }).catch((err)=>{
               if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                console.log(err)
            })
        },
        
    }
  }
  </script>
  