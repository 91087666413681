<template>
    <div @mousedown="dragMouseDown" :id="'draggableContainer-'+indx" ref="draggableContainer" class="card col-4 overflow-hidden cursor-pointer bg-warning-soft border-warning warning-boxshadow pricing-business-plus floating-card">
        <div class="bg-holder d-dark-none" style="background-image:url(../../assets/img/bg/bg-11.png);background-position:left bottom;background-size:auto;">
        </div>
        <!--/.bg-holder-->

        <div class="bg-holder d-light-none" style="background-image:url(../../assets/img/bg/bg-11-dark.png);background-position:left bottom;background-size:auto;">
        </div>
        <!--/.bg-holder-->

        <div class=" card-body d-flex flex-column justify-content-between position-relative ">
            <div class="text-end">
                <span class="aviso-close text-warning"><WindowMinimize @click="showaviso = !showaviso" /></span>
            </div>
            <div class="d-flex justify-content-between" >
                <div class=" me-3" :class="[(showaviso ? 'mb-5 mb-md-0 mb-lg-5' : '')] ">
                    <div class="d-sm-flex align-items-center mb-3">
                        <h3 class="mb-0">Aviso IASC</h3>
                        <span class="badge ms-sm-3 text-uppercase fs--2 bg-warning">IMPORTANTE</span>
                    </div>
                
                    <div class="d-flex align-items-end mb-md-5 mb-lg-0" v-if="showaviso">
                        <h5 class="fw-bolder me-1">{{aviso.aviso}}</h5>
                    </div>
                </div>
                <img class="d-dark-none" v-if="showaviso" src="@/assets/img/spot-illustrations/star.png" width="54" height="54" alt="">
                <img class="d-light-none" v-if="showaviso" src="@/assets/img/spot-illustrations/star-dark.png" width="54" height="54" alt="">
            </div>
            <div class="row flex-1 justify-content-end" v-if="showaviso">
                <div class="col-sm-8 col-md-12">
                    <div class="d-sm-flex d-md-block d-lg-flex justify-content-end align-items-end h-100">
                    <ul class="list-unstyled mb-0 border-start-sm border-start-md-0 border-start-lg ps-sm-5 ps-md-0 ps-lg-5 border-warning-300">
                        <li class="d-flex align-items-center"><span class="uil uil-check-circle text-success me-2"></span><span class="text-700 fw-semi-bold">
                            Nuestros agentes ya atienden esta situación
                        </span></li>
                        <li class="d-flex align-items-center"><span class="uil uil-check-circle text-success me-2"></span><span class="text-700 fw-semi-bold">
                            Daremos solución lo antes posible.
                        </span></li>
                        <li class="d-flex align-items-center"><span class="uil uil-check-circle text-success me-2"></span><span class="text-700 fw-semi-bold">
                            No es necesario dar de alta un ticket relacionado a este tema.
                        </span></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WindowMinimize  from 'vue-material-design-icons/WindowMinimize.vue'
export default {
    name: 'AvisosApp',
    props:['aviso','indx'],
    components:{ WindowMinimize },
    data(){
        return{
            showaviso: true,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            }
        }
    },
    mounted(){
        if(document.querySelector("#draggableContainer-"+(this.indx - 1))){
            let height = document.querySelector("#draggableContainer-"+(this.indx - 1)).clientHeight
            this.$refs.draggableContainer.style.top = (height+65) + 'px'
        }
    },
    methods:{
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement () {
            document.onmouseup = null
            document.onmousemove = null
        }
    }
}
</script>